import { BaseComponent } from "@abstract/BaseComponent";
import { Component } from "@angular/core";
import { GoPuffUploadService } from "../../gopuffUpload.service";
import { TableEditableService } from "../../../components/table-editable/tableEditable.service";
import { TemplateService } from "../../../components/templates/template.service";
import { DlgCreateOrderListSuccess } from "../dlg-create-order-list-success";
import { Const } from "@const/Const";

@Component({
  selector: 'gopuff-upload-data-table',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class GopuffDataTableComponent extends BaseComponent {
  constructor(private gopuffUploadService: GoPuffUploadService, private tableEditableService: TableEditableService) {
    super();
  }

  data: any[] = []
  columns = []
  canNext:boolean = false
  isLoading: boolean = false
  ftlOrder: any;
  mode: string;
  listOrders: any[] = [];

  ngOnInit(): void {
    super.ngOnInit();
    this.initData();
    this.gopuffUploadService.mode.subscribe(value => this.mode = value);
    this.gopuffUploadService.ftlOrder.subscribe(value => this.ftlOrder = value);
  }

  async initData() {
    const templateId = this.gopuffUploadService.templateId.getValue();
    const templateService = new TemplateService();
    templateService.setTemplateId(templateId);
    this.columns = templateService.getValidationsFromTemplate();
    this.data = this.gopuffUploadService.getDataFromExcel();
    if(!this.data.length) return;
    let clientId = this.gopuffUploadService.clientId.getValue();
    let pickupTime = await this.gopuffUploadService.getWarehousePickupTime();
    this.data.forEach((row) => {
      row.clientId = clientId;
      row.pickupTime = pickupTime;
    })
    this.tableEditableService.setData(this.data);
    this.tableEditableService.setSubscription();
    console.log("inittable", this.data);
  }

  onChange(data) {
    // console.log("onchange", data);
    this.gopuffUploadService.editingData.next(data);
    this.validate();
  }

  back() {
    this.gopuffUploadService.step.next(2);
  }
  next() {
    const templateId = this.gopuffUploadService.templateId.getValue();
    if (templateId == 'GoPuffUpdatePallet') return this.updateOrderItem({ action: "update-items" });
    else if (templateId == 'GoPuffUpdateReferenceNumbers') return this.updateOrderItem({ action: "update-refNums" });
    else {
      const uploadData = this.gopuffUploadService.submit();
      let url = `${Const.APIV2('orders')}/create-order-gopuff/json`;
      this.isLoading = true;
      this.api.POST(url, uploadData, { observe: "response" }).subscribe((resp) => {
        this.isLoading = false;
        this.listOrders = resp?.body?.data?.list_data;
        this.showDialogCloneComplete(this.listOrders);
      },
      (err) => {
        this.isLoading = false;
        this.showErr(err);
      })
    }
    
  }

  private updateOrderItem({ action }: { action: string }) {
    const uploadData = this.gopuffUploadService.submit();
    let url = `${Const.APIV2('orders')}/update-order-gopuff`;
    this.isLoading = true;
    this.api.POST(url, { ...uploadData, action }, { observe: "response" }).subscribe((resp) => {
      this.isLoading = false;
      this.listOrders = resp?.body?.data?.list_data;
      this.showDialogCloneComplete(this.listOrders, false);
    },
    (err) => {
      this.isLoading = false;
      this.showErr(err);
    })
  }

  private showDialogCloneComplete(orders, isCreated = true) {
    let isShowFtlOrder = this.mode == 'both' || this.mode == 'linehaulOnly';
    this.modalService.create({
      nzContent: DlgCreateOrderListSuccess,
      nzFooter: null,
      nzClosable: false,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzComponentParams: { 
        orders,
        ftlOrder: isShowFtlOrder ? this.ftlOrder : null,
        isCreated,
        onSuccess: () => {
          this.gopuffUploadService.reset();
          this.router.navigate([this.routeAdminOrderList]);
        },
        onExport: () => {
          this.gopuffUploadService.downloadResults(this.listOrders);
        }
      }
    });
  }

  validate() {
    //check if all field in data is valid
    for (let row of this.data) {
      const keys = Object.keys(row);
      const errors = row._errors;
      if (!errors) continue;
      for (let key of keys) {
        if (errors[key]) {
          // console.log("error", row, key, errors[key]);
          this.canNext = false;
          return;
        }
      }
    }
    this.canNext = true;
  }
}