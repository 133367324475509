import { iTemplate } from "../interface";
import { GoPuffByPallet } from "./gopuff-by-pallet";
import { GoPuffByOrder } from "./gopuff-by-order";
import { GoPuffUpdatePallet } from "./gopuff-update-pallet";
import { GoPuffUpdateReferenceNumbers } from "./gopuff-update-refNums";


export const Templates: {[templaetName: string]: iTemplate} = {
  GoPuffByPallet,
  GoPuffUpdatePallet,
  GoPuffUpdateReferenceNumbers,
  GoPuffByOrder
}